import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as indexItLcRhC3SOMeta } from "/opt/buildhome/repo/pages/alternatives/index.vue?macro=true";
import { default as _91slug_93x8nwzxMykbMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as features7k68KUoyy4Meta } from "/opt/buildhome/repo/pages/features.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as lean_45canvasf49tyxurNQMeta } from "/opt/buildhome/repo/pages/lean-canvas.vue?macro=true";
import { default as _91slug_93zwznHQgrgNMeta } from "/opt/buildhome/repo/pages/people/[slug].vue?macro=true";
import { default as indexddrjzUXrecMeta } from "/opt/buildhome/repo/pages/people/index.vue?macro=true";
import { default as _91slug_93xsAOt4mlffMeta } from "/opt/buildhome/repo/pages/pitches/[slug].vue?macro=true";
import { default as index7yKL1l9h0gMeta } from "/opt/buildhome/repo/pages/pitches/index.vue?macro=true";
import { default as portfolioOEYTPLG89MMeta } from "/opt/buildhome/repo/pages/portfolio.vue?macro=true";
import { default as indexVrpVjnZSa3Meta } from "/opt/buildhome/repo/pages/services/index.vue?macro=true";
import { default as _91slug_93FmhTEiPl31Meta } from "/opt/buildhome/repo/pages/tools/[slug].vue?macro=true";
import { default as index4aR1mPd18dMeta } from "/opt/buildhome/repo/pages/tools/index.vue?macro=true";
import { default as value_45based_45pricing_45canvasEjFjlI0YrRMeta } from "/opt/buildhome/repo/pages/value-based-pricing-canvas.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about___en",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexItLcRhC3SOMeta?.name ?? "alternatives___en",
    path: indexItLcRhC3SOMeta?.path ?? "/alternatives",
    meta: indexItLcRhC3SOMeta || {},
    alias: indexItLcRhC3SOMeta?.alias || [],
    redirect: indexItLcRhC3SOMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/alternatives/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x8nwzxMykbMeta?.name ?? "blog-slug___en",
    path: _91slug_93x8nwzxMykbMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    alias: _91slug_93x8nwzxMykbMeta?.alias || [],
    redirect: _91slug_93x8nwzxMykbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog___en",
    path: indexnCitm8tluDMeta?.path ?? "/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact___en",
    path: contactupLWW5VsuQMeta?.path ?? "/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: features7k68KUoyy4Meta?.name ?? "features___en",
    path: features7k68KUoyy4Meta?.path ?? "/features",
    meta: features7k68KUoyy4Meta || {},
    alias: features7k68KUoyy4Meta?.alias || [],
    redirect: features7k68KUoyy4Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/features.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lean_45canvasf49tyxurNQMeta?.name ?? "lean-canvas___en",
    path: lean_45canvasf49tyxurNQMeta?.path ?? "/lean-canvas",
    meta: lean_45canvasf49tyxurNQMeta || {},
    alias: lean_45canvasf49tyxurNQMeta?.alias || [],
    redirect: lean_45canvasf49tyxurNQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/lean-canvas.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zwznHQgrgNMeta?.name ?? "people-slug___en",
    path: _91slug_93zwznHQgrgNMeta?.path ?? "/people/:slug()",
    meta: _91slug_93zwznHQgrgNMeta || {},
    alias: _91slug_93zwznHQgrgNMeta?.alias || [],
    redirect: _91slug_93zwznHQgrgNMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexddrjzUXrecMeta?.name ?? "people___en",
    path: indexddrjzUXrecMeta?.path ?? "/people",
    meta: indexddrjzUXrecMeta || {},
    alias: indexddrjzUXrecMeta?.alias || [],
    redirect: indexddrjzUXrecMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/people/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xsAOt4mlffMeta?.name ?? "pitches-slug___en",
    path: _91slug_93xsAOt4mlffMeta?.path ?? "/pitches/:slug()",
    meta: _91slug_93xsAOt4mlffMeta || {},
    alias: _91slug_93xsAOt4mlffMeta?.alias || [],
    redirect: _91slug_93xsAOt4mlffMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/pitches/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7yKL1l9h0gMeta?.name ?? "pitches___en",
    path: index7yKL1l9h0gMeta?.path ?? "/pitches",
    meta: index7yKL1l9h0gMeta || {},
    alias: index7yKL1l9h0gMeta?.alias || [],
    redirect: index7yKL1l9h0gMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/pitches/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioOEYTPLG89MMeta?.name ?? "portfolio___en",
    path: portfolioOEYTPLG89MMeta?.path ?? "/portfolio",
    meta: portfolioOEYTPLG89MMeta || {},
    alias: portfolioOEYTPLG89MMeta?.alias || [],
    redirect: portfolioOEYTPLG89MMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: indexVrpVjnZSa3Meta?.name ?? "services___en",
    path: indexVrpVjnZSa3Meta?.path ?? "/services",
    meta: indexVrpVjnZSa3Meta || {},
    alias: indexVrpVjnZSa3Meta?.alias || [],
    redirect: indexVrpVjnZSa3Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FmhTEiPl31Meta?.name ?? "tools-slug___en",
    path: _91slug_93FmhTEiPl31Meta?.path ?? "/tools/:slug()",
    meta: _91slug_93FmhTEiPl31Meta || {},
    alias: _91slug_93FmhTEiPl31Meta?.alias || [],
    redirect: _91slug_93FmhTEiPl31Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/tools/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4aR1mPd18dMeta?.name ?? "tools___en",
    path: index4aR1mPd18dMeta?.path ?? "/tools",
    meta: index4aR1mPd18dMeta || {},
    alias: index4aR1mPd18dMeta?.alias || [],
    redirect: index4aR1mPd18dMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/tools/index.vue").then(m => m.default || m)
  },
  {
    name: value_45based_45pricing_45canvasEjFjlI0YrRMeta?.name ?? "value-based-pricing-canvas___en",
    path: value_45based_45pricing_45canvasEjFjlI0YrRMeta?.path ?? "/value-based-pricing-canvas",
    meta: value_45based_45pricing_45canvasEjFjlI0YrRMeta || {},
    alias: value_45based_45pricing_45canvasEjFjlI0YrRMeta?.alias || [],
    redirect: value_45based_45pricing_45canvasEjFjlI0YrRMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/value-based-pricing-canvas.vue").then(m => m.default || m)
  }
]